<template>
  <div class="animated fadeIn">
    <b-card title="TurnPerDay">

      <b-form @submit.prevent="searchFn">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-2 my-1">
            {{ $t("message.year") }} :
            <br />
            <b-form-select v-model="timeSel" :options="language== 'th'? timeOptTH: timeOptEN" v-on:change="selectTime" />
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 my-1">
            {{ $t("message.machineIdFarm") }} :
            <br />
            <multi-list-select :list="machineData" option-value="machineId" option-text="name"
              :selected-items="selectedMachine" :placeholder="$t('message.all')" @select="onSelectMachine">
            </multi-list-select>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 my-1">
            {{ $t("message.selectDateTime") }} :
            <br />
            <date-time-picker v-model="searchData.datetime"></date-time-picker>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-2 my-3">
            <b-btn class="col-sm-12 col-md-12 ml-12 mr-3 my-1" variant="primary" type="submit">
              <i class="fa fa-search"></i>
              &nbsp;{{ $t("message.search") }}
            </b-btn>
          </div>
        </div>
        <br />
      </b-form>

      <section id="chart">
        <div class="row"  v-if="series_dryer[0].data != ''">
          <div class="col-sm col-12 col-sm-12 col-md-6">
            <h4 style="text-align: center;">{{ $t("message.washingMachine")}}</h4>
            <div id="chart" clase="chart" >
              <apexchart type="line" height="350" :options="chartOptions_washer" :series="series_washer"></apexchart>
            </div>
          </div>
          <div class="col-sm col-12 col-sm-12 col-md-6">
            <h4 style="text-align: center;">{{ $t("message.dryerMachine")}}</h4>
            <div id="chart" clase="chart">
              <apexchart type="line" height="350" :options="chartOptions_dryer" :series="series_dryer"></apexchart>
            </div>
          </div>
        </div>


        <div class="col-sm col-12 col-sm-12 col-md-12 my-4" v-if="series[0].data != ''">
          <h4 style="text-align: center;">{{ $t("message.listOfAllMachines")}}</h4>
          <div id="chart" clase="chart table-responsive" >
            <apexchart type="line" height="350" :options="chartOptions_device" :series="series_device"></apexchart>
          </div>
        </div>

      </section>



      <section id="table">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-7" v-if="series[0].data != ''">
            <div class="table-responsive">
              <table class="table-grid">
                <thead style="text-align-last: center">
                  <th >{{ $t("message.machineId") }}</th>
                  <!-- <th >รหัสครื่อง</th> -->
                  <th>{{ $t("message.deviceId") }}</th>
                  <th >{{ $t("message.type") }}</th>
                  <th >{{ $t("message.size") }}</th>
                  <!-- <th >รุ่น</th> -->
                  <th >{{ $t("message.sale") }}</th>
                  <th >{{ $t("message.transactions") }}</th>
                  <th >TurnPerDay</th>
                </thead>
                <tbody>
                  <template v-for="row in TurnPerDay">
                    <tr>
                      <td style="text-align-last: center">{{ row.machineId }}</td>
                      <!-- <td>{{ row.deviceIndex }} </td> -->
                      <td style="text-align-last: center">{{ row.device.id }} </td>
                      <td>{{ row.device.deviceDetails.type }} </td>
                      <td style="text-align: right;">{{ row.device.description }} </td>
                      <!-- <td>{{ row.device.deviceDetails.name }} </td> -->
                      <td style="text-align: right;">{{ Number(row.total).toLocaleString() }} </td>
                      <td style="text-align: right;">{{ Number(row.trans).toLocaleString() }} </td>
                      <td style="text-align: right;">{{ Number(row.tuenPerDay).toLocaleString(undefined, {maximumFractionDigits: 2 })}} </td>
                    </tr>
                  </template>
                  <tr>
                    <td colspan="4"> </td>
                    <td style="text-align: right;">{{ Number(TurnPerDay_sumTotal).toLocaleString(undefined, { maximumFractionDigits: 2 })}}</td>
                    <td style="text-align: right;">{{ Number(TurnPerDay_sumTrans).toLocaleString(undefined, { maximumFractionDigits: 2 })}} </td>
                    <td style="text-align: right;">{{ Number(TurnPerDay_sumTurnPerDay).toLocaleString(undefined, { maximumFractionDigits: 2 })}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-5" v-if="series[0].data != ''">
            <div class="table-responsive">
              <table class="table-grid">
                <thead style="text-align-last: center">
                  <th>{{ $t("message.type") }}</th>
                  <th>{{ $t("message.size") }}</th>
                  <th>{{ $t("message.sale") }}</th>
                  <th>{{ $t("message.transactions") }}</th>
                  <th>{{ $t("message.quantity") }}</th>
                  <th>{{ $t("message.average") }}</th>
                  <!-- <th>จำนวนวัน</th> -->
                  <th>TurnPerDay</th>
                </thead>
                <tbody>
                  <template v-for="row in TurnPerDayGroupDevice">
                    <tr>
                      <td>{{ row.device.deviceDetails.type }} </td>
                      <td style="text-align: right;">{{ row.device.description }} </td>
                      <td style="text-align: right;">{{ Number(row.total).toLocaleString(undefined, {
                          maximumFractionDigits: 2
                        })
                      }} </td>
                      <td style="text-align: right;">{{ Number(row.trans).toLocaleString(undefined, {
                          maximumFractionDigits: 2
                        })
                      }} </td>
                      <td style="text-align: right;">{{ Number(row.amountDevice).toLocaleString() }} </td>
                      <td style="text-align: right;">{{ Number(row.averageUse).toLocaleString(undefined, {
                          maximumFractionDigits: 2
                        })
                      }} </td>
                      <!-- <td>{{ row.amountDate }} </td> -->
                      <td style="text-align: right;">{{ Number(row.tuenPerDay).toLocaleString(undefined, {
                          maximumFractionDigits: 2
                        })
                      }} </td>
                    </tr>
                  </template>
                  <tr>
                    <td colspan="2"> </td>
                    <td style="text-align: right;">{{ Number(TurnPerDayGroupDevic_sumTotal).toLocaleString(undefined,
                        { maximumFractionDigits: 2 })
                    }} </td>
                    <td style="text-align: right;">{{ Number(TurnPerDayGroupDevic_sumTrans).toLocaleString(undefined, {
                        maximumFractionDigits: 2
                      })
                    }}</td>

                    <td style="text-align: right;">{{ Number(TurnPerDayGroupDevic_sumDevice).toLocaleString(undefined, {
                        maximumFractionDigits: 2
                      })
                    }} </td>
                    <td style="text-align: right;">{{ Number(TurnPerDayGroupDevic_sumTrans /
                        TurnPerDayGroupDevic_sumDevice).toLocaleString(undefined, { maximumFractionDigits: 2 })
                    }} </td>
                    <td style="text-align: right;">{{ Number((TurnPerDayGroupDevic_sumTrans /
                        TurnPerDayGroupDevic_sumDevice) / TurnPerDayGroupDevic_amountDate).toLocaleString(undefined, {
                          maximumFractionDigits: 2
                        })
                    }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

    </b-card>
  </div>
</template>
<script>
import Vue from "vue";
import webServices from "../../script";
import DateTimePicker from "../../components/DateTimePicker/Custom";

import {
  MultiSelect,
  MultiListSelect,
} from "../../custom_modules/search-select";
import moment, { relativeTimeThreshold } from "moment";
import VueJsonPretty from "vue-json-pretty";
import { Chart } from "chart.js";

export default {
  name: "orders",
  components: {
    DateTimePicker,
    MultiSelect,
    MultiListSelect,
    VueJsonPretty,

  },
  data() {
    return {
      api: {},
      timeSel: 0,
      timeOptTH: [
        { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
        { value: 1, text: '<2022'}
      ],
      timeOptEN: [
        { value: 0, text: '2022 To This Year' },
        { value: 1, text: '<2022'}
      ],
      language: window.localStorage.getItem("language"),
      role_id: window.localStorage.getItem("roleID"),
      userBusiness: window.localStorage.getItem("business"),

      selectedProduct: [],
      selectedMachine: [],
      machineData: [],
      selectedId: {},
      searchData: {
        // datetime: "",
        datetime: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
      },

      series: [
        {
          name: 'Turn',
          type: 'column',
          data: []
        },
      ],

      avg_TurnPerDay: 0,
      chartOptions: {
        series: [],
        chart: {
          height: 350,
          type: 'bar',
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [1, 1, 4]
        },
        colors: ['#229954', '#87ceeb',],
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            min: 0,
            max:  () => this.avg_TurnPerDay,
            tickAmount: 4,
          },
        ],
      },

      series_washer: [
        {
          name: 'Turn',
          type: 'column',
          data: []
        },
      ],
      chartOptions_washer: {
        series: [],
        chart: {
          height: 350,
          type: 'bar',
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [1, 1, 4]
        },
        colors: ['#87ceeb',],
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
          min: 0,
          max:  () => this.avg_TurnPerDay,
          tickAmount: 4,
          }
        ],
      },

      series_dryer: [
        {
          name: 'Turn',
          type: 'column',
          data: []
        },
      ],
      chartOptions_dryer: {
        series: [],
        chart: {
          height: 350,
          type: 'bar',
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [1, 1, 4]
        },
        colors: ['#FEC748',],
        xaxis: {
          categories: [],
        },
        yaxis: [
        {
          min: 0,
          max:  () => this.avg_TurnPerDay,
          tickAmount: 4,
          }
        ],
      },

      series_device: [
        {
          name: 'Turn',
          type: 'column',
          data: []
        },
      ],
      chartOptions_device: {
        series: [],
        chart: {
          height: 350,
          type: 'bar',
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#ff4560',],
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            min: 0,
            tickAmount: 4,
            max:  () => this.avg_TurnPerDay,
          }
        ],
      },

      TurnPerDay: {},
      TurnPerDayGroupDevice: {},

      TurnPerDayGroupDevic_sumTotal: 0,
      TurnPerDayGroupDevic_sumTrans: 0,
      TurnPerDayGroupDevic_sumDevice: 0,
      TurnPerDayGroupDevic_amountDate: 0,

      TurnPerDay_sumTotal: 0,
      TurnPerDay_sumTrans: 0,
      TurnPerDay_sumDevice: 0,
      TurnPerDay_amountDate: 0,
      // TurnPerDay_sumAverageUse: 0,
      TurnPerDay_sumTurnPerDay: 0,
      solution: 'น้ำยา',
    };
  },
  async mounted() {
    await this.setParams(this.$route.query);
    // await this.getProduct();
    await this.getMachine();

    if (
      !this.$isRole("admin", this.role_id) &&
      !this.$isRole("callcenter", this.role_id)
    ) {
      await this.getTransactions();
    }
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },

    setParams(query) {
      if (Object.keys(query).length != 0) {
        // console.log(query)
        this.fromdate = query.from;
        this.todate = query.to;
        this.searchData.datetime = [query.from, query.to];
        this.searchData.productId = query.productId || "";
        this.searchData.machineId = query.machineId || "";
      }
    },

    getMachine() {
      this.$Progress.start();
      this.axios
        .get(`/machines/list`)
        .then((res) => {
          this.$Progress.finish();
          this.machineData = res.data;
          if (this.machineData.length == 1) {
            this.selectedMachine = this.machineData;
          }
        })
        .catch((err) => {
          this.$Progress.fail();
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            },
          });
          console.log("error @machine");
          console.log(err);
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },

    async searchFn() {
      this.series[0].data = [];
      this.series_washer[0].data = [];
      this.series_dryer[0].data = [];
      this.series_device[0].data = [];
      this.chartOptions.xaxis.categories = [];
      this.chartOptions_washer.xaxis.categories = [];
      this.chartOptions_dryer.xaxis.categories = [];
      this.chartOptions_device.xaxis.categories = [];

      this.TurnPerDayGroupDevic_sumTotal = 0;
      this.TurnPerDayGroupDevic_sumTrans = 0;
      this.TurnPerDayGroupDevic_sumDevice = 0;
      this.TurnPerDayGroupDevic_amountDate = 0;

      this.TurnPerDay_sumTotal = 0;
      this.TurnPerDay_sumTrans = 0;
      this.TurnPerDay_sumDevice = 0;
      this.TurnPerDay_amountDate = 0;
      // this.TurnPerDay_sumAverageUse = 0;
      this.TurnPerDay_sumTurnPerDay = 0;

      var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest

      await this.getTurnPerDay();
      await this.getTurnPerDayGroupDevice();
    },


    async getTurnPerDay() {
      const params = {
        ...this.searchData,
        machine: this.selectedMachine,
        from: this.searchData.from = moment(this.searchData.datetime[0]).format("YYYY-MM-DD HH:mm:ss"),
        to: this.searchData.to = moment(this.searchData.datetime[1]).format("YYYY-MM-DD HH:mm:ss"),
        // type: "washing",
        // notThinkStatus: ['CANCEL_BY_MC_FAIL', 'CANCEL_PAYMENT', 'CANCEL_TIMEOUT']
      }

      // params.machineId = this.searchData.machineId.join(',')
      // params.datetime = params.datetime.join(',')
      // params.machine = params.machine.join(',')
      // params.notThinkStatus = params.notThinkStatus.join(',')

      console.log('params', params)
      await this.axios
        .get(`/analyze/turnperday`, { params })
        .then(res => {
          console.log(res.data.row)
          if (res.data) {
            this.TurnPerDay = res.data.row

            let turnMax = 0
            for (var i = 0; i < this.TurnPerDay.length; i++) {

              if(this.TurnPerDay[i].device.deviceDetails.type != 'vending'){
                this.series_device[0].data.push(this.TurnPerDay[i].tuenPerDay)
                this.chartOptions_device.xaxis.categories.push(["No "+this.TurnPerDay[i].device.id, this.TurnPerDay[i].device.description, this.TurnPerDay[i].device.deviceDetails.type])
                // this.chartOptions_device.xaxis.categories.push("No "+ this.TurnPerDay[i].device.id  + " " + this.TurnPerDay[i].device.description + " " + this.TurnPerDay[i].device.deviceDetails.type)

                if (this.TurnPerDay[i].tuenPerDay > turnMax) {
                  turnMax = this.TurnPerDay[i].tuenPerDay;
                }
              }

              this.TurnPerDay_sumTotal = this.TurnPerDay[i].total + this.TurnPerDay_sumTotal
              this.TurnPerDay_sumTrans = this.TurnPerDay[i].trans + this.TurnPerDay_sumTrans
              this.TurnPerDay_sumDevice = this.TurnPerDay[i].amountDevice + this.TurnPerDay_sumDevice
              // this.TurnPerDay_sumAverageUse = parseFloat(this.TurnPerDay[i].averageUse) + this.TurnPerDay_sumAverageUse
              this.TurnPerDay_sumTurnPerDay = this.TurnPerDay[i].tuenPerDay + this.TurnPerDay_sumTurnPerDay
              this.TurnPerDay_amountDate = this.TurnPerDay[i].amountDate

            }
            this.chartOptions.colors.push( "#33CC99", );
            this.avg_TurnPerDay = Math.ceil(turnMax)  + 1

            console.log('avg_TurnPerDay', this.avg_TurnPerDay)
          }
        })
        .catch(err => {
          this.$Progress.fail()
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: err
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },


    async getTurnPerDayGroupDevice() {
      const params = {
        ...this.searchData,
        machine: this.selectedMachine,
        from: this.searchData.from = moment(this.searchData.datetime[0]).format("YYYY-MM-DD HH:mm:ss"),
        to: this.searchData.to = moment(this.searchData.datetime[1]).format("YYYY-MM-DD HH:mm:ss"),
        // type: "washing",
        // notThinkStatus: ['CANCEL_BY_MC_FAIL', 'CANCEL_PAYMENT', 'CANCEL_TIMEOUT'],
      }

      // params.machineId = this.searchData.machineId.join(',')
      // params.datetime = params.datetime.join(',')
      // params.machine = params.machine.join(',')
      // params.notThinkStatus = params.notThinkStatus.join(',')

      await this.axios
        .get(`/analyze/turnperday/groupdevice`, { params })
        .then(res => {
          this.TurnPerDayGroupDevice = res.data.data
          console.log('groupdevice xxx', this.TurnPerDayGroupDevice)
          if (res.data) {

            for (var i = 0; i < this.TurnPerDayGroupDevice.length; i++) {
 
              // this.chartOptions.xaxis.categories.push(this.TurnPerDayGroupDevice[i].device.description + " "  +this.TurnPerDayGroupDevice[i].device.deviceDetails.type)
              this.chartOptions.xaxis.categories.push([this.TurnPerDayGroupDevice[i].device.description, this.TurnPerDayGroupDevice[i].device.deviceDetails.type])

              this.TurnPerDayGroupDevic_sumTotal = this.TurnPerDayGroupDevice[i].total + this.TurnPerDayGroupDevic_sumTotal
              this.TurnPerDayGroupDevic_sumTrans = this.TurnPerDayGroupDevice[i].trans + this.TurnPerDayGroupDevic_sumTrans
              this.TurnPerDayGroupDevic_sumDevice = this.TurnPerDayGroupDevice[i].amountDevice + this.TurnPerDayGroupDevic_sumDevice
              this.TurnPerDayGroupDevic_amountDate = this.TurnPerDayGroupDevice[i].amountDate

              this.TurnPerDayGroupDevice[i].tuenPerDay = this.TurnPerDayGroupDevice[i].tuenPerDay

              if(this.TurnPerDayGroupDevice[i].device.deviceDetails.type != 'vending'){
                this.series[0].data.push(this.TurnPerDayGroupDevice[i].tuenPerDay)
              }

              if(this.TurnPerDayGroupDevice[i].device.deviceDetails.type == 'washer'){
                this.series_washer[0].data.push(this.TurnPerDayGroupDevice[i].tuenPerDay)
                this.chartOptions_washer.xaxis.categories.push([this.TurnPerDayGroupDevice[i].device.description, this.TurnPerDayGroupDevice[i].device.deviceDetails.type])
              }

              if(this.TurnPerDayGroupDevice[i].device.deviceDetails.type == 'dryer'){
                this.series_dryer[0].data.push(this.TurnPerDayGroupDevice[i].tuenPerDay)
                this.chartOptions_dryer.xaxis.categories.push([this.TurnPerDayGroupDevice[i].device.description, this.TurnPerDayGroupDevice[i].device.deviceDetails.type])
              }
            }

          }
        })
        .catch(err => {
          this.$Progress.fail()
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: err
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },


    onSelectMachine(items) {
      // console.log(items)
      this.selectedMachine = items;
      this.searchData.machineId = this.selectedMachine.map(
        (data) => data.machineId
      );
    },

    onSelect(items) {
      // console.log(items)
      this.selectedProduct = items;
      this.searchData.productId = this.selectedProduct.map((data) => data.id);
    },


    changeTab(params) {
      this.rowData = {};
      this.totalRows = 0;
      this.totalSubRows = 0;
      this.summary = {};
      this.searchData.type = params;
      if (
        !this.$isRole("admin", this.role_id) &&
        !this.$isRole("callcenter", this.role_id)
      ) {
        this.searchFn();
      }
    },
  },
};
</script>
